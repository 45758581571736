<template>
  <div class="progress">
    <div class="progress--value" :style="{width: `${value}%`}"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: ['value'],
};
</script>

<style lang="scss">
  @import 'ProgressBar.scss';
</style>
