import Vue from 'vue';

const Config = {
  state: {
    animationChat: true,
    animationChatSpeed: 1,
    linkExternalCalendar: 'https://calendly.com/efl-demo/demonstration-navis',
    linkExternalMementis: 'https://boutique.efl.fr/mementis-offre',
    linkEternalRGPD: 'https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf',
    solutionsTypeOrder: null,
  },

  getters: {
    getSolutionsTypeOrder: (state) => state.solutionsTypeOrder,
  },

  mutations: {
    SET_SOLUTION_TYPE_ORDER: (state, payload) => {
      const solutionsTypeOrder = payload || ['documentation', 'logiciel', 'communication', 'plateforme'];
      Vue.set(state, 'solutionsTypeOrder', solutionsTypeOrder);
    },
  },

  actions: {
    fetchSolutionOrder: ({ commit }, data) => {
      commit('SET_SOLUTION_TYPE_ORDER', data);
    },
  },
};

export default Config;
