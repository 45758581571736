import {
  configApiProduct as Repository,
} from './config';

export default {
  get(productSku) {
    return Repository().get(
      '/searchProducts',
      {
        params: {
          skus: productSku,
        },
      },
    );
  },
};
