import Vue from 'vue';

import Lead from '../../repositories/Lead';

const User = {
  state: {
    uuid: '',
    userIdCpt: 0,
    firstName: '',
    lastName: '',
    email: '',
    function: '',
    missions: [],
    size: '',
    solution: '',
  },

  getters: {
    getUuid: (state) => state.uuid,
    getUserIdCpt: (state) => state.userIdCpt,
    getFirstName: (state) => state.firstName,
    getLastName: (state) => state.lastName,
    getEmail: (state) => state.email,
    getFunction: (state) => state.function,
    getMissions: (state) => state.missions,
    getSize: (state) => state.size,
    getSolution: (state) => state.solution,
  },

  mutations: {
    UPDATE_USER_IDS: (state, payload) => {
      Vue.set(state, 'uuid', payload.uuid);
      Vue.set(state, 'userIdCpt', payload.id);
    },

    UPDATE_FIRST_NAME: (state, payload) => {
      Vue.set(state, 'firstName', payload);
    },

    UPDATE_LAST_NAME: (state, payload) => {
      Vue.set(state, 'lastName', payload);
    },

    UPDATE_EMAIL: (state, payload) => {
      Vue.set(state, 'email', payload);
    },

    UPDATE_FUNCTION: (state, payload) => {
      Vue.set(state, 'function', payload);
    },

    UPDATE_MISSIONS: (state, payload) => {
      Vue.set(state, 'missions', payload);
    },

    UPDATE_SIZE: (state, payload) => {
      Vue.set(state, 'size', payload);
    },

    UPDATE_SOLUTION: (state, payload) => {
      Vue.set(state, 'solution', payload);
    },
  },

  actions: {
    fetchUserIds: ({
      commit,
    }) => Lead.getUuid().then((res) => {
      commit('UPDATE_USER_IDS', res.data);
    }),

    postUser: (store, opts) => new Promise((resolve) => {
      const body = new FormData();
      // form lead
      body.append('prenom', store.getters.getFirstName);
      body.append('nom', store.getters.getLastName);
      body.append('email', store.getters.getEmail);
      body.append('metier', store.getters.getAnswerLabels('question_1', [store.getters.getFunction]));
      body.append('missions', JSON.stringify(store.getters.getMissions));
      body.append('taille', store.getters.getSize);
      body.append('solution', store.getters.getSolution);
      body.append('sku', store.getters.getMainProduct.sku);

      if (opts.isEG) {
        // form eg
        body.append('civilite', opts.params.civility || '');
        body.append('rs', opts.params.rs || '');
        body.append('tel', opts.params.phone || '');

        body.append('adresse', opts.params.address || '42, rue de villiers');
        body.append('ville', opts.params.city || 'Levallois');
        body.append('cp', opts.params.zipCode || '92300');

        body.append('sujet', 'portail-eg');
        body.append('campagne', '21CFG1A');
        body.append('duree', '21');
        body.append('optin', opts.params.optin || '');
        body.append('productname', store.getters.getMainProduct.name || '');

        body.append('relatedProduct1Name', opts.params.relatedProduct1Name);
        body.append('relatedProduct1Sku', opts.params.relatedProduct1Sku);
        body.append('relatedProduct2Name', opts.params.relatedProduct2Name);
        body.append('relatedProduct2Sku', opts.params.relatedProduct2Sku);
        body.append('relatedProduct3Name', opts.params.relatedProduct3Name);
        body.append('relatedProduct3Sku', opts.params.relatedProduct3Sku);
      }

      const headers = {
        uuid: store.getters.getUuid,
      };

      Lead.postUser(
        body,
        headers,
      ).then(() => {
        resolve('success');
      }).catch(() => {
        resolve('error');
      });
    }),

    updateFirstName: ({
      commit,
    }, val) => {
      commit('UPDATE_FIRST_NAME', val);
    },

    updateLastName: ({
      commit,
    }, val) => {
      commit('UPDATE_LAST_NAME', val);
    },

    updateEmail: ({
      commit,
    }, val) => {
      commit('UPDATE_EMAIL', val);
    },

    updateFunction: ({
      commit,
    }, val) => {
      commit('UPDATE_FUNCTION', val);
    },

    updateMissions: ({
      commit,
    }, val) => {
      commit('UPDATE_MISSIONS', val);
    },

    updateSize: ({
      commit,
    }, val) => {
      commit('UPDATE_SIZE', val);
    },

    updateSolution: ({
      commit,
    }, val) => {
      commit('UPDATE_SOLUTION', val);
    },
  },
};

export default User;
