<template>
  <svg
    class="loaderDoted"
    style="width: 33px;height: 16px;display:inline-block;"
    version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 33 9" enable-background="new 0 0 0 0" xml:space="preserve">
    <circle :fill="color" stroke="none" cx="2.5" cy="4.5" r="2.5">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 2; 0 -2; 0 2"
        repeatCount="indefinite"
        begin="0.1"/>
      <animate
        attributeName="opacity"
        values="0;1;0" dur="1s"
        repeatCount="indefinite"/>
    </circle>
    <circle :fill="color" stroke="none" cx="15.5" cy="4.5" r="2.5">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 2; 0 -2; 0 2"
        repeatCount="indefinite"
        begin="0.2"/>
      <animate
        attributeName="opacity"
        values="0;1;0" dur="1s" begin="0.2"
        repeatCount="indefinite"/>
    </circle>
    <circle :fill="color" stroke="none" cx="28.5" cy="4.5" r="2.5">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 2; 0 -2; 0 2"
        repeatCount="indefinite"
        begin="0.3"/>
      <animate
        attributeName="opacity"
        values="0;1;0" dur="1s" begin="0.3"
        repeatCount="indefinite"/>
    </circle>
  </svg>
</template>

<script>
export default {
  name: 'loaderDot',

  props: [
    'color',
  ],
};
</script>
