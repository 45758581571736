import {
  tagClick,
  tagPageView,
} from '../../plugins/tags';

const Tags = {
  state: {
    tags: {
      // ////////////
      // ////// CLICK
      // ////////////
      // CTA GLOBAL
      goToEflShop_clic: {
        tealium_event: 'goToEflShop_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      telephone_clic: {
        tealium_event: 'telephone_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      legalMentions_clic: {
        tealium_event: 'legalMentions_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      rgpd_clic: {
        tealium_event: 'rgpd_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contactConseiller_clic: {
        tealium_event: 'contactConseiller_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contactConseiller_clic2: {
        tealium_event: 'recontacter_final_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contactConseiller_clic3: {
        tealium_event: 'recontacterproduct2_final_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contactConseiller_clic4: {
        tealium_event: 'recontacterproduct3_final_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      // CTA« Oui commencons»
      conversation_start: {
        tealium_event: 'conversation_start',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      function_clic: {
        // CTA« Quelle est votre profession»
        tealium_event: 'function_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      poi_clic: {
        // POI(centres d’ intérêt)
        tealium_event: 'poi_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      effectif_clic: {
        // Taille salariale
        tealium_event: 'effectif_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      solution_clic: {
        // Type de solution recherchée
        tealium_event: 'solution_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      firstname_clic: {
        // Prénom
        tealium_event: 'firstname_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      name_clic: {
        // Nom
        tealium_event: 'name_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      email_clic: {
        // Email
        tealium_event: 'email_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      eg_clic_droite: {
        // Je débute mon EG
        tealium_event: 'eg_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      eg_clic: {
        // Je débute mon EG
        tealium_event: 'eg_clic_droite',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      eg_clic_gauche: {
        // Je débute mon EG block à gauche
        tealium_event: 'eg_clic_gauche',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      demo_clic: {
        // Je souhaite une démo
        tealium_event: 'demo_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      recontacter_clic: {
        // Vous pouvez me recontacter ?
        tealium_event: 'recontacter_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      autres_solutions_clic: {
        // CTA« On vous présente 3 autres solutions ? »
        tealium_event: 'autres_solutions_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contact_produit2: {
        // Contact commercial produit 2
        tealium_event: 'contact_produit2',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contact_produit3: {
        // Contact commercial produit 3
        tealium_event: 'contact_produit3',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      contact_produit4: {
        // Contact commercial produit 4
        tealium_event: 'contact_produit4',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      infos_produit1: {
        // Voir plus d’ infos produit 1
        tealium_event: 'infos_produit1',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      infos_produit2: {
        // Voir plus d’ infos produit 2
        tealium_event: 'infos_produit2',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      infos_produit3: {
        // Voir plus d’ infos produit 3
        tealium_event: 'infos_produit3',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      infos_produit4: {
        // Voir plus d’ infos produit 4
        tealium_event: 'infos_produit4',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      reponse_attente: {
        // Réponses à vos attentes O / N ?
        tealium_event: 'reponse_attente',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      Form_error: {
        // Erreur formulaire produit1 EG
        tealium_event: 'Form_error',
        event_category: 'CTA',
        event_action: 'Page vue',
      },
      // CTA_Je_demande_mon_essai_gratuit
      eg_final_clic: {
        tealium_event: 'eg_final_clic',
        event_category: 'CTA',
        event_action: 'Clic',
      },
      // /////////////////
      // ////// PAGE VIEW
      // /////////////////
      home_view: {
        // Page d’ accueil
        tealium_event: 'home_view',
        page_name: 'Home',
        page_url: '/home',
      },
      pageproposal1_view: {
        // Page proposition 1
        tealium_event: 'pageproposal1_view',
        page_name: 'Page proposition 1',
        page_url: '/pageproposition1',
      },
      pageproposal2_view: {
        // Page proposition 2
        tealium_event: 'pageproposal2_view',
        page_name: 'Page proposition 2',
        page_url: '/pageproposition2',
      },
      formeg1_view: {
        // Formulaire produit 1 EG 1 ère partie
        tealium_event: 'formeg1_view',
        page_name: 'Formulaire EG etape 1',
        page_url: '/formeg1',
      },
      formeg2_view: {
        // Formulaire produit 1 EG 2 partie
        tealium_event: 'formeg2_view',
        page_name: 'Formulaire EG etape 2',
        page_url: '/formeg2',
      },
      formcontact1_view: {
        tealium_event: 'formcontact1_view',
        page_name: 'Formulaire contact 1',
        page_url: '/formcontact1',
      },
      formcontact2_view: {
        tealium_event: 'formcontactproduct2_view',
        page_name: 'Formulaire contact produit 2',
        page_url: '/formcontactproduit2',
      },
      formcontact3_view: {
        tealium_event: 'formcontactproduct3_view',
        page_name: 'Formulaire contact produit 3',
        page_url: '/formcontactproduit3',
      },
      formcontact4_view: {
        tealium_event: 'formcontactproduct4_view',
        page_name: 'Formulaire contact produit 4',
        page_url: '/formcontactproduit4',
      },
    },
  },
  getters: {
    getTagEvent: (state) => (key, label) => {
      const tagEvent = state.tags[key];

      if (label) {
        return {
          event_label: label,
          ...tagEvent,
        };
      }

      return {
        ...tagEvent,
      };
    },
  },
  mutations: {},
  actions: {
    triggerTagClick: (store, opts) => {
      const params = store.getters.getTagEvent(
        opts.key,
        opts.label,
      );

      tagClick(params);
    },

    triggerTagView: (store, opts) => {
      const params = store.getters.getTagEvent(
        opts.key,
      );

      tagPageView(params);
    },
  },
};

export default Tags;
