import Vue from 'vue';
import xlsx from 'xlsx';

import getProductList from '../../repositories/ProductsList';

const ProductsList = {
  state: {
    productsList: [],
    productsListFiltered: [],
  },

  getters: {
    getProductsList: (state) => state.productsList,

    getProductsListFiltered: (state) => state.productsListFiltered,

    getProductsIds: (state) => {
      // product[6] => SKU
      const productsIds = [];

      state.productsListFiltered.forEach((product) => {
        const newSku = product[6];
        if (!productsIds.includes(newSku)) {
          productsIds.push(newSku);
        }
      });

      return {
        mainId: productsIds[0],
        relatedId: `${productsIds[1]};${productsIds[2]};${productsIds[3]}`,
      };
    },

    getProductsIdsNoSolution: (state, getters) => {
      // product[2] => solution
      // product[4] => order
      // product[6] => SKU
      const solutionOrder = getters.getSolutionsTypeOrder;
      const productsUniqueSku = [];
      const productFiltered = [[], [], [], []];
      const productFormatted = [];
      const offset = solutionOrder.length;

      // GET unique product
      state.productsListFiltered.forEach((product) => {
        solutionOrder.forEach((solutionType, i) => {
          if (solutionType === product[2] && !productsUniqueSku.includes(product[6])) {
            productsUniqueSku.push(product[6]);
            productFiltered[i].push(product);
          }
        });
      });

      // Format product by solutionOrder
      // [0][1][2][3]
      // [4][5][6][7]
      // [8][9][10][11]
      // [12][13][14][15]
      // ===>
      // [0][4][8][12][1][5][9][13][2][6][10][14][3][7][11][15]
      productFiltered.forEach((products, i) => {
        products.forEach((product, j) => {
          productFormatted[offset * j + i] = product;
        });
      });

      // clean empty cells from array
      const products = productFormatted.filter((item) => item);

      return {
        mainId: products[0][6],
        relatedId: `${products[1][6]};${products[2][6]};${products[3][6]}`,
      };
    },
  },

  mutations: {
    RESET_PRODUCTS_LIST: (state) => {
      Vue.set(state, 'productsList', []);
    },

    STORE_PRODUCTS_LIST: (state, payload) => {
      const file = new Uint8Array(payload);
      const workbook = xlsx.read(file, { type: 'array' });
      const productsList = [];

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

        if (sheet.length) {
          sheet.shift();
          productsList.push(...sheet);
        }
      });

      Vue.set(state, 'productsList', productsList);
    },

    UPDATE_FILTERED_PRODUCT: (state, payload) => {
      const filters = payload;
      const products = state.productsList;

      const productsFiltered = filters
        .reduce((arr, key, index) => {
          if (key) {
            if (typeof key === 'string' || typeof key === 'number') {
              return arr.filter((row) => row[index] === key);
            }

            const tempArr = [];
            key.forEach((k) => {
              const a = arr.filter((row) => row[index] === k);
              tempArr.push(...a);
            });
            return tempArr;
          }

          return arr;
        }, products);

      productsFiltered.sort((a, b) => a[4] - b[4]);

      Vue.set(state, 'productsListFiltered', productsFiltered);
    },
  },

  actions: {
    fetchProductList: ({ commit }) => new Promise((resolve) => {
      commit('RESET_PRODUCTS_LIST');

      getProductList.get().then((res) => {
        commit('STORE_PRODUCTS_LIST', res.data);
        resolve();
      });
    }),

    updateFilteredProduct: ({ commit }, filters) => {
      commit('UPDATE_FILTERED_PRODUCT', filters);
    },
  },
};

export default ProductsList;
