<template>
  <div class="productCard" v-if="product">
    <div class="productCard__title">
      <span class="emoji">&#x1F947;</span>
      {{ product.name }}
      <span
        v-if="product.subtitle"
        v-html="` - ${product.subtitle}`"
      ></span>
    </div>

    <div class="productCard__aside">
      <div class="productImage">
        <img :src="product.image_url" :alt="product.name" />
      </div>

      <div class="block">
        <priceBox :price="product.final_price || product.original_price" />

        <a
          class="link--alt"
          :href="product.product_url"
          @click="triggerTagClick({
            key: 'infos_produit1',
            label: 'Voir plus d’infos',
          })"
          target="_blank"
          title="Voir plus d’infos"
        >Voir plus d’infos</a>
      </div>
    </div>

    <div class="productCard__content" v-html="product.description_api" />
    <!--
    Expected structure from API
    <div class="productCard__content__item">
      <span><img src="{{media_url}}"></span>
      <p>{{content}}</p>
    </div>
    -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PriceBox from '../priceBox/PriceBox.vue';

export default {
  name: 'ProductCard',

  components: {
    PriceBox,
  },

  props: [
    'product',
  ],

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },
};
</script>

<style lang="scss">
  @import 'ProductCard.scss';
</style>
