<template>
  <v-row justify="center">
    <v-col cols="12" sm="8" md="6">
      <div class="formCard">
        <div class="formCard__title">
          Être mis en relation avec un conseiller !
        </div>

        <div class="formCard__stepper" />

        <div class="formCard__content mt-4">
          <v-form
            ref="formContact"
            v-model="formContactIsValid"
            lazy-validation
            @submit.prevent
            onSubmit="return false;"
          >
            <v-text-field
              v-model="firstname"
              label="Prénom"
              :rules="rulesRequiredLength()"
              outlined
              required
              @update:error="error($event, 'prenom')"
            ></v-text-field>

            <v-text-field
              v-model="lastname"
              label="Nom"
              :rules="rulesRequiredLength()"
              outlined
              required
              @update:error="error($event, 'nom')"
            ></v-text-field>

            <v-text-field
              v-model="email"
              label="Email"
              :rules="rulesRequiredLengthEmail()"
              outlined
              required
              @update:error="error($event, 'email')"
            ></v-text-field>

            <v-text-field
              v-model="phone"
              v-mask="'## ## ## ## ##'"
              label="Téléphone"
              :rules="rulesRequiredPhone()"
              outlined
              required
              pattern="\d*"
              @update:error="error($event, 'téléphone')"
            ></v-text-field>

            <transition
              name="fade"
              mode="out-in"
            >
              <a
                class="button full"
                v-ripple
                v-if="!formIsPosted"
                @click.stop="validFormContact"
              >Envoyer</a>

              <div
                v-if="formIsPosted"
                class="wysiwyg mt-2"
              >
                <p
                  style="color: var(--darkBlue);"
                >Demande de mise en relation avec un conseiller envoyée.</p>
              </div>
            </transition>
          </v-form>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Contact from '../../repositories/Contact';
import {
  rulesRequiredLength,
  rulesRequiredPhone,
  rulesRequiredLengthEmail,
} from '../../plugins/validator';

export default {
  name: 'FormContact',

  data() {
    return {
      formContactIsValid: true,
      formIsPosted: false,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    };
  },

  props: {
    index: Number,
  },

  mounted() {
    this.firstname = this.getFirstName;
    this.lastname = this.getLastName;
    this.email = this.getEmail;
  },

  methods: {
    rulesRequiredLength,
    rulesRequiredPhone,
    rulesRequiredLengthEmail,

    ...mapActions([
      'triggerTagClick',
    ]),

    validFormContact() {
      const formIsValid = this.$refs.formContact.validate();

      if (formIsValid) {
        this.sendEmail();
        this.triggerTagClick({
          key: `contactConseiller_clic${this.index}`,
          label: 'Mise en relation avec un conseiller',
        });
      }
    },

    sendEmail() {
      const body = new FormData();
      body.append('nom', this.lastname);
      body.append('prenom', this.firstname);
      body.append('email', this.email);
      body.append('telephone', this.phone);

      body.append('productInterest', this.getMainProduct.name);
      body.append('productLink', this.getMainProduct.product_url);

      body.append('function', this.getAnswerLabels('question_1', [this.getFunction]));
      body.append('missions', this.getAnswerLabels('question_2', this.getMissions));
      body.append('size', this.getAnswerLabels('question_3', [this.getSize]));
      body.append('solution', this.getAnswerLabels('question_4', [this.getSolution]));

      body.append('relatedProduct1', this.getRelatedProducts[0].name || '');
      body.append('relatedProduct2', this.getRelatedProducts[1].name || '');
      body.append('relatedProduct3', this.getRelatedProducts[2].name || '');

      const headers = {
        uuid: this.getUuid,
      };

      Contact.postEmail(
        body,
        headers,
      ).then(() => {
        this.formIsPosted = true;
      });
    },

    error(ev, field) {
      if (ev) {
        this.triggerTagClick({
          key: 'Form_error',
          label: `Error_formproduit#${this.index || 1} ${field}`,
        });
      }
    },
  },

  computed: {
    ...mapGetters([
      'getFirstName',
      'getLastName',
      'getEmail',
      'getUuid',
      'getFunction',
      'getMissions',
      'getSize',
      'getSolution',
      'getMainProduct',
      'getRelatedProducts',
      'getAnswerLabels',
    ]),
  },
};
</script>
