<template>
  <div class="chatItem__actions">
    <a
      class="chatItem__button coral"
      v-ripple
      @click.stop="
        $emit('openForm'),
        triggerTagClick({
          key: 'eg_clic_droite',
          label: 'Je débute mon essai gratuit de 10 jours',
        })
      "
    >Je débute mon essai gratuit de 10 jours</a>

    <a
      :href="$store.state.Config.linkExternalCalendar"
      @click="triggerTagClick({
        key: 'demo_clic',
        label: 'Je souhaite une démo',
      })"
      target="_blank"
      class="chatItem__button"
      v-ripple
    >Je souhaite une démo</a>

    <a
      class="chatItem__button"
      v-ripple
      @click.stop="
        $emit('openFormContact'),
        triggerTagClick({
          key: 'recontacter_clic',
          label: 'Vous pouvez me recontacter ?',
        })
      "
    >Vous pouvez me recontacter ?</a>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProductCardActionsRule1',

  props: {
    url: String,
  },

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },
};
</script>
