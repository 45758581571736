<template>
  <div class="timerDisplay">{{ time }}</div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'ChatItemClock',

  data() {
    return {
      time: '',
    };
  },

  mounted() {
    this.time = dayjs().format('HH:mm');
  },
};
</script>
