<template>
  <v-row justify="center">
    <v-col cols="12" md="6" class="formEG-productPreview">
      <formSideBar product="TODO:ObjectProductFromStore"/>
    </v-col>

    <v-col cols="12" sm="8" md="6" lg="4">
      <div class="formCard">
        <div class="formCard__title">
          Je teste gratuitement et sans engagement pendant 10 jours !
        </div>

        <div class="formCard__stepper">
          <a v-ripple :class="{ 'is-active': tab === 0 }"
            @click.stop="tab = 0"
          >Step 1</a>

          <a v-ripple :class="{ 'is-active': tab === 1 }"
            @click.stop="validFormStep1()"
          >Step 2</a>
        </div>

        <v-tabs v-model="tab" grow>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item>
              <div class="formCard__content">
                <v-form
                  ref="formStep1"
                  v-model="formStep1Valid"
                  lazy-validation
                >
                  <v-radio-group
                    v-model="civility"
                    :rules="rulesRequiredLength()"
                    row
                    id="civilite"
                    @update:error="error($event, 'civilité')"
                  >
                    <v-radio label="Mme" value="Mme"></v-radio>
                    <v-radio label="M" value="M"></v-radio>
                  </v-radio-group>

                  <v-text-field
                    @input="updateFirstName($event)"
                    :value="getFirstName"
                    label="Prénom"
                    :rules="rulesRequiredLength()"
                    outlined
                    required
                    id="prenom"
                    @update:error="error($event, 'prenom')"
                  ></v-text-field>

                  <v-text-field
                    @input="updateLastName($event)"
                    :value="getLastName"
                    label="Nom"
                    :rules="rulesRequiredLength()"
                    outlined
                    required
                    id="nom"
                    @update:error="error($event, 'nom')"
                  ></v-text-field>

                  <v-text-field
                    @input="updateEmail($event)"
                    :value="getEmail"
                    label="Email"
                    type="email"
                    :rules="rulesRequiredLengthEmail()"
                    outlined
                    required
                    id="email"
                    @update:error="error($event, 'email')"
                  ></v-text-field>

                  <v-select
                    :items="getAnswerFormattedVSelect('question_1')"
                    :value="getFunction"
                    @input="updateFunction($event)"
                    :rules="rulesRequiredLength()"
                    label="Fonction"
                    outlined
                    required
                    id="fonction"
                    @update:error="error($event, 'metier')"
                  ></v-select>

                  <a class="button full" v-ripple @click.stop="validFormStep1">Je finalise</a>
                </v-form>
              </div>
            </v-tab-item>

            <v-tab-item>
              <div class="formCard__content">
                <v-form
                  ref="formStep2"
                  v-model="formStep2Valid"
                  lazy-validation
                >
                  <v-radio-group
                    v-model="step2type"
                    :rules="rulesRequiredLength()"
                    @change="resetFormValidation('formStep2')"
                    row
                    required
                    id="type"
                  >
                    <v-radio label="Professionnel" value="professionnel"></v-radio>
                    <v-radio label="Particulier" value="particulier"></v-radio>
                  </v-radio-group>

                  <v-text-field
                    :class="{'d-none' : step2type === 'particulier'}"
                    :disabled="step2type === 'particulier'"
                    v-model="rs"
                    label="Dénomination sociale"
                    :rules="step2type !== 'particulier' ? rulesRequiredLength() : []"
                    outlined
                    @update:error="error($event, 'rs')"
                    id="denomimation-social"
                  ></v-text-field>

                  <v-text-field
                    v-model="address"
                    label="Adresse"
                    :rules="rulesRequiredLength()"
                    outlined
                    required
                    @update:error="error($event, 'adresse')"
                    id="adresse"
                  ></v-text-field>

                  <v-text-field
                    v-model="zipCode"
                    v-mask="'#####'"
                    label="Code postal"
                    :rules="rulesRequiredLengthMaxLength5()"
                    outlined
                    required
                    @update:error="error($event, 'code postal')"
                    id="code-postal"
                  ></v-text-field>

                  <v-text-field
                    v-model="city"
                    label="Ville"
                    :rules="rulesRequiredLength()"
                    outlined
                    required
                    @update:error="error($event, 'ville')"
                    id="ville"
                  ></v-text-field>

                  <v-text-field
                    v-model="phone"
                    v-mask="'## ## ## ## ##'"
                    label="Téléphone"
                    :rules="rulesRequiredPhone()"
                    outlined
                    required
                    pattern="\d*"
                    @update:error="error($event, 'téléphone')"
                    id="telephone"
                  ></v-text-field>

                  <v-checkbox
                    v-if="step2type === 'particulier'"
                    v-model="checkboxCommercial"
                    label="En cochant cette case, j'accepte de recevoir des
                      prospections commerciales en lien avec mes centres d'intérêts,
                      et ai connaissance qu'elles pourraient être diffusées à
                      des partenaires chargés de l'exécution de ces missions
                      par les sociétés du groupe LEFEBVRE-SARRUT."
                    required
                  ></v-checkbox>

                  <transition name="fade" mode="out-in">
                    <a
                      class="button full"
                      @click="
                        validFormStep2(),
                        triggerTagClick({
                          key: 'eg_final_clic',
                          label: 'CTA_Je_demande_mon_essai_gratuit',
                        })
                      "
                      v-ripple
                      :disabled="formStep2Loading"
                      v-if="!formIsSent"
                    >
                      <span v-if="!formStep2Loading">Je demande mon essai gratuit</span>
                      <v-progress-circular
                        v-else
                        indeterminate
                      ></v-progress-circular>
                    </a>

                    <p v-if="formIsSent">Votre demande d'essai a bien été prise en compte,
                      <a @click="$emit('closeDialog')">Fermer le formulaire</a>.</p>
                  </transition>

                  <transition name="fade">
                    <p v-if="formErrMessage" style="color: var(--error);">
                      Une erreur est survenue pendant l'envoi de votre formulaire</p>
                  </transition>

                  <p class="small" style="margin-top: 32px;">
                    En validant ce formulaire, vous reconnaissez avoir pris
                    connaissance de la «Politique de confidentialité» et vous acceptez que
                    les informations recueillies à partir de ce formulaire soient traitées
                    par Editions Francis Lefebvre, membre du groupe LEFEBVRE SARRUT dans
                    le but de gérer mes demandes, commandes et abonnements, de constituer
                    un fichier clientèle et à des fins de statistiques et
                    de prospection commerciale.
                  </p>

                  <p class="small">Pour en savoir plus sur la gestion de vos données personnelles
                    et pour exercer vos droits, reportez-vous à notre
                    <a href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf"
                    target="_blank">
                      Politique de confidentialité.
                    </a>
                  </p>
                </v-form>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FormSideBar from './formSideBar/FormSideBar.vue';
import {
  rulesRequiredLength,
  rulesRequiredPhone,
  rulesRequiredLengthMaxLength5,
  rulesRequiredLengthEmail,
} from '../../plugins/validator';

export default {
  name: 'FormContent',

  components: {
    FormSideBar,
  },

  data() {
    return {
      // tab index, base index 0
      tab: 0,

      // form
      formIsSent: false,
      formErrMessage: false,

      // form step 1
      formStep1Valid: true,
      civility: '',

      // form step 2
      formStep2Valid: true,
      formStep2Loading: false,
      step2type: '',
      rs: '',
      address: '',
      zipCode: '',
      city: '',
      phone: '',
      checkboxCommercial: false,
    };
  },

  methods: {
    ...mapActions([
      'updateFirstName',
      'updateLastName',
      'updateEmail',
      'updateFunction',
      'postUser',
      'triggerTagClick',
      'triggerTagView',
    ]),

    rulesRequiredLength,
    rulesRequiredPhone,
    rulesRequiredLengthMaxLength5,
    rulesRequiredLengthEmail,

    error(ev, field) {
      if (ev) {
        this.triggerTagClick({
          key: 'Form_error',
          label: `Error_form ${field}`,
        });
      }
    },

    validFormStep1() {
      const formIsValid = this.$refs.formStep1.validate();

      if (formIsValid) {
        this.tab = 1;
      } else {
        this.dispatchTagEvent(this.$refs.formStep1.inputs);
      }
    },

    validFormStep2() {
      const formIsValid = this.$refs.formStep2.validate();

      if (formIsValid) {
        this.formStep2Loading = true;
        const params = this.getEGParam();

        // open new window with EG_URL
        const EG_URL = this.buildEGUrl(params);
        window.open(EG_URL);

        // update lead info
        this.postUser({
          isEG: true,
          params: {
            ...params,
            relatedProduct1Name: this.getRelatedProducts[0].name || '',
            relatedProduct1Sku: this.getRelatedProducts[0].sku || '',
            relatedProduct2Name: this.getRelatedProducts[1].name || '',
            relatedProduct2Sku: this.getRelatedProducts[1].sku || '',
            relatedProduct3Name: this.getRelatedProducts[2].name || '',
            relatedProduct3Sku: this.getRelatedProducts[2].sku || '',
          },
        }).then((type) => {
          switch (type) {
            case 'success':
              this.formStep2Loading = false;
              this.formIsSent = true;
              break;

            case 'error':
              this.formStep2Loading = false;
              this.formErrMessage = true;
              break;

            default:
              break;
          }
        });
      } else {
        this.dispatchTagEvent(this.$refs.formStep2.inputs);
      }
    },

    dispatchTagEvent(inputs) {
      inputs.forEach((input) => {
        if (!input.validate()) {
          this.triggerTagClick({
            key: 'Form_error',
            label: `error_${input.id}`,
          });
        }
      });
    },

    resetFormValidation(form) {
      this.$refs[form].resetValidation();
    },

    getEGParam() {
      const formId = 90000;
      const userId = this.getUserIdCpt;
      const subject = 'portail-eg';
      const campagne = '21CFG1A';
      const duration = 21;
      const rs = this.step2type === 'particulier' ? 'P' : (this.rs || 'CQPRM EGI 2015');
      const commercial = this.checkboxCommercial ? 'O' : 'N';
      const optin = this.step2type === 'professionnel' ? 'NR' : commercial;

      return {
        formId,
        userId,
        subject,
        productName: this.getMainProduct.name,
        campagne,
        sku: this.getMainProduct.sku || 'KNAVEG',
        duration,
        rs,
        civility: this.civility,
        lastName: this.getLastName,
        firstName: this.getFirstName,
        email: this.getEmail,
        address: this.address.length ? this.address : '42, rue de villiers',
        phone: this.phone,
        zipCode: this.zipCode.length ? this.zipCode : '92300',
        city: this.city.length ? this.city : 'Levallois',
        function: this.getFunction,
        optin,
      };
    },

    buildEGUrl(params) {
      const BASE_URL = process.env.VUE_APP_EFL_EG_BASEURL;
      const EG_URL = `${BASE_URL}?`
        + `idform=${params.formId}`
        + `&idcpt=${params.userId}`
        + `&sujet=${params.subject}`
        + `&productname=${params.productName}`
        + `&campagne=${params.campagne}`
        + `&sku=${params.sku}`
        + `&duree=${params.duration}`
        + `&rs=${params.rs}`
        + `&civilite=${params.civility}`
        + `&nom=${params.lastName}`
        + `&prenom=${params.firstName}`
        + `&email=${params.email}`
        + `&adresse=${params.address}`
        + `&tel=${params.phone}`
        + `&cp=${params.zipCode}`
        + `&ville=${params.city}`
        + `&fonction=${params.function}`
        + `&optin=${params.optin}`;

      return EG_URL;
    },
  },

  mounted() {
    this.triggerTagView({
      key: 'formeg1_view',
    });
  },

  watch: {
    tab(index) {
      if (index === 0) {
        this.triggerTagView({
          key: 'formeg1_view',
        });
      } else if (index === 1) {
        this.triggerTagView({
          key: 'formeg2_view',
        });
      }
    },
  },

  computed: {
    ...mapGetters([
      'getMainProduct',
      'getUserIdCpt',
      'getFirstName',
      'getLastName',
      'getEmail',
      'getQuestion',
      'getAnswerFormattedVSelect',
      'getFunction',
      'getRelatedProducts',
    ]),
  },
};
</script>

<style lang="scss">
  @import 'FormContent.scss';
</style>
