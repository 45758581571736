<template>
  <div class="productCardRelated">
    <div class="productCardRelated__cat" v-html="productCategories" />

    <div class="productCardRelated__title" v-html="product.name" />

    <div class="productCardRelated__hookphrase" v-html="product.hook_phrase" />

    <!-- <div class="productCardRelated__content" v-html="product.short_description" /> -->

    <price-box :price="product.final_price || product.original_price"/>

    <a
      :href="product.product_url"
      @click="triggerTagClick({
        key: `infos_produit${index}`,
        label: 'Voir plus d’infos',
      })"
      class="link--alt"
      target="_blank"
      title="Voir plus d’infos"
    >Voir plus d’infos</a>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import PriceBox from '../priceBox/PriceBox.vue';

export default {
  name: 'ProductCardRelated',

  components: {
    PriceBox,
  },

  props: [
    'product',
    'index',
  ],

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },

  computed: {
    productCategories() {
      if (this.product.categories) {
        const categories = this.product.categories.split(';');
        return categories.pop();
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
  @import 'ProductCardRelated.scss';
</style>
