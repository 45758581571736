export function rulesRequiredLength() {
  return [
    (v) => !!v || 'Champ requis',
  ];
}

export function rulesRequiredPhone() {
  return [
    (v) => !!v || 'Champ requis',
    (v) => v.length === 14 || 'Saisir un numéro valide : 0X XX XX XX XX',
  ];
}

export function rulesRequiredLengthMaxLength5() {
  return [
    (v) => !!v || 'Champ requis',
    (v) => v.length === 5 || 'Saisir un code postal valide : XXXXX',
  ];
}

export function rulesRequiredLengthEmail() {
  return [
    (v) => !!v || 'Champ requis',
    (v) => /\S+@\S+\.\S+/.test(v) || 'Saisir un email valide : mail@exemple.fr',
  ];
}
