import Vue from 'vue';

import Product from '../../repositories/Product';

const AppText = {
  state: {
    products: [],
    mainProduct: [],
    relatedProducts: [],
  },

  getters: {
    getMainProduct: (state) => state.mainProduct[0] || false,
    getRelatedProducts: (state) => state.relatedProducts.slice(0, 3),
  },

  mutations: {
    SET_MAIN_PRODUCT: (state, payload) => {
      Vue.set(state, 'mainProduct', payload);
    },

    SET_RELATED_PRODUCTS: (state, payload) => {
      const ids = payload.productsIds.split(';');
      const products = ids.map((id) => payload.res.filter((product) => product.sku === id)[0]);
      // remove undefined value in case of a wrong mapping between the API and Xlxs config file
      const filterProducts = products.filter((product) => product !== undefined);

      Vue.set(state, 'relatedProducts', filterProducts);
    },
  },

  actions: {
    fetchProduct: (store, productsIds) => Product.get(productsIds).then((res) => res.data),

    setMainProduct: ({ dispatch, commit }, productsIds) => new Promise((resolve) => {
      dispatch('fetchProduct', productsIds).then((res) => {
        commit('SET_MAIN_PRODUCT', res);
        resolve();
      });
    }),

    setRelatedProducts: ({ dispatch, commit }, productsIds) => new Promise((resolve) => {
      dispatch('fetchProduct', productsIds).then((res) => {
        commit('SET_RELATED_PRODUCTS', {
          res,
          productsIds,
        });
        resolve();
      });
    }),
  },
};

export default AppText;
