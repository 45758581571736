var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appHeader"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"7","sm":"4"}},[_c('img',{staticClass:"appHeader__logo",attrs:{"src":require('@/assets/images/logo-efl--landscape.png'),"alt":"Editions Francis Lefebvre"}})]),_c('v-spacer'),_c('v-col',{staticClass:"d-md-none appHeader__actionSmall"},[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"href":"tel:+33183101010"},on:{"click":function($event){return _vm.triggerTagClick({
            key: 'telephone_clic',
            label: '+33183101010',
          })}}},[_c('img',{attrs:{"src":require('@/assets/images/icons/icon-phone.svg'),"alt":"phone"}})]),_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],on:{"click":function($event){_vm.dialogMenuMobile = !_vm.dialogMenuMobile}}},[_c('img',{attrs:{"src":require('@/assets/images/icons/icon-menu.svg'),"alt":"menu"}})])]),_c('v-col',{staticClass:"appHeader--telto d-none d-md-block",attrs:{"cols":"auto"}},[_c('a',{attrs:{"href":"tel:+33183101010"},on:{"click":function($event){return _vm.triggerTagClick({
            key: 'telephone_clic',
            label: '+33183101010',
          })}}},[_vm._v("01 83 10 10 10")]),_c('p',[_vm._v(" Du lundi au vendredi "),_c('br'),_vm._v(" de 9h à 18h (appel gratuit) ")])]),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"auto"}},[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button hero",attrs:{"href":"https://boutique.efl.fr/","title":"boutique.elf.fr","target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
            key: 'goToEflShop_clic',
            label: 'Accèder à la boutique',
          })}}},[_c('span',{staticClass:"text"},[_vm._v("Accéder à la boutique")]),_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/assets/images/icons/icon-arrow-right.svg'),"alt":""}})])])])],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","content-class":"v-dialog--small","transition":"dialog-top-transition"},model:{value:(_vm.dialogMenuMobile),callback:function ($$v) {_vm.dialogMenuMobile=$$v},expression:"dialogMenuMobile"}},[_c('v-container',{staticClass:"navMobile__holder"},[_c('v-row',{staticClass:"dialogHeader",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('a',{staticClass:"link--close",attrs:{"title":"Fermer"},on:{"click":function($event){_vm.dialogMenuMobile = false}}},[_vm._v(" Fermer "),_c('img',{attrs:{"src":require('../../assets/images/icons/icon-close.svg'),"alt":""}})])])],1),_c('div',{staticClass:"navMobile"},[_c('div',{staticClass:"navMobile__telto"},[_c('a',{attrs:{"href":"tel:+33183101010"},on:{"click":function($event){return _vm.triggerTagClick({
              key: 'telephone_clic',
              label: '+33183101010',
            })}}},[_vm._v("01 83 10 10 10")]),_c('p',[_vm._v(" Du lundi au vendredi "),_c('br'),_vm._v(" de 9h à 18h (appel gratuit) ")])]),_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"navMobile__cta",attrs:{"href":"https://boutique.efl.fr/","title":"boutique.elf.fr","target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
            key: 'goToEflShop_clic',
            label: 'Accèder à la boutique',
          })}}},[_c('span',{staticClass:"text"},[_vm._v("Accèder à la boutique")]),_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/assets/images/icons/icon-arrow-right-coral.svg'),"alt":""}})])]),_c('ul',{staticClass:"navMobile__link"},[_c('li',[_c('span',[_vm._v("Copyright © "+_vm._s(_vm.year)+" Editions Francis Lefebvre")])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$emit('openLegalMentions'),
                _vm.dialogMenuMobile=false,
                _vm.triggerTagClick({
                  key: 'legalMentions_clic',
                  label: 'Mentions légales',
                })}}},[_vm._v("Mentions légales")])]),_c('li',[_c('a',{attrs:{"href":"https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf","target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
              key: 'rgpd_clic',
              label: 'Politique de confidentialité des données',
            })}}},[_vm._v("Politique de confidentialité des données")])]),_c('a',{attrs:{"href":"javascript:Didomi.preferences.show()"}},[_vm._v("Gérer mon consentement")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }