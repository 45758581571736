<template>
  <v-dialog
    v-model="visible"
    :width="width"
    content-class="confirmAction"
  >
    <v-card>
      <v-card-title>
        <div class="wysiwyg">
          <h3>{{ content }}</h3>
        </div>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>

        <a
          class="link--alt"
          v-ripple
          @click="$emit('close')"
        >Annuler</a>

        <a
          class="button small "
          v-ripple
          @click="$emit('confirm')"
        >Confirmer</a>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmationAction',

  props: [
    'visible',
    'width',
    'content',
  ],
};
</script>
