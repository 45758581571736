<template>
  <div class="priceBox" :class="{ 'invert' : invert}">
    <span class="before">{{ priceFormatted[1] }}</span>
    <strong>{{ priceFormatted[2] }}</strong>
    <span class="after">{{ priceFormatted[3] }}</span>
  </div>
</template>

<script>
export default {
  name: 'PriceBox',

  props: [
    'price',
    'invert',
  ],

  data() {
    return {
      prices: this.price,
    };
  },

  computed: {
    priceFormatted() {
      // eslint-disable-next-line
      const regex = /^(.* )?(\d+ ?\d+,\d+\D€) (.*)/; // regex contain a white space, API return one.
      return this.prices.match(regex);
    },
  },
};
</script>
