var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appFooter d-none d-md-block"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('ul',[_c('li',[_c('span',[_vm._v("Copyright © "+_vm._s(_vm.year)+" Editions Francis Lefebvre")])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$emit('openLegalMentions'),
              _vm.triggerTagClick({
                key: 'legalMentions_clic',
                label: 'Mentions légales',
              })}}},[_vm._v("Mentions légales")])]),_c('li',[_c('a',{attrs:{"href":_vm.urlRGPD,"target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
              key: 'rgpd_clic',
              label: 'Politique de confidentialité des données',
            })}}},[_vm._v("Politique de confidentialité des données")])]),_c('li',[_c('a',{attrs:{"href":"javascript:Didomi.preferences.show()"}},[_vm._v("Gérer mon consentement")])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }