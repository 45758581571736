import { render, staticRenderFns } from "./ChatItemCard.vue?vue&type=template&id=5d33dcb6&"
import script from "./ChatItemCard.vue?vue&type=script&lang=js&"
export * from "./ChatItemCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports