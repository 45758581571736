<template>
  <div class="appHeader">
    <v-container fluid>
      <v-row align="center">
        <v-col cols="7" sm="4">
          <img class="appHeader__logo"
            :src="require('@/assets/images/logo-efl--landscape.png')"
            alt="Editions Francis Lefebvre">
        </v-col>

        <v-spacer></v-spacer>

        <!-- START display mobile -->
        <v-col class="d-md-none appHeader__actionSmall">
          <a
            href="tel:+33183101010"
            v-ripple
            @click="triggerTagClick({
              key: 'telephone_clic',
              label: '+33183101010',
            })"
          >
            <img :src="require('@/assets/images/icons/icon-phone.svg')" alt="phone">
          </a>

          <a @click="dialogMenuMobile = !dialogMenuMobile" v-ripple>
            <img :src="require('@/assets/images/icons/icon-menu.svg')" alt="menu">
          </a>
        </v-col>
        <!-- END display mobile -->

        <!-- START display large -->
        <v-col cols="auto" class="appHeader--telto d-none d-md-block">
          <a
            @click="triggerTagClick({
              key: 'telephone_clic',
              label: '+33183101010',
            })"
            href="tel:+33183101010"
          >01 83 10 10 10</a>
          <p>
            Du lundi au vendredi
            <br/>
            de 9h à 18h (appel gratuit)
          </p>
        </v-col>

        <v-col cols="auto" class="d-none d-md-block">
          <a
            class="button hero"
            v-ripple
            href="https://boutique.efl.fr/"
            title="boutique.elf.fr"
            target="_blank"
            @click="triggerTagClick({
              key: 'goToEflShop_clic',
              label: 'Accèder à la boutique',
            })"
          >
            <span class="text">Accéder à la boutique</span>
            <span class="icon">
              <img :src="require('@/assets/images/icons/icon-arrow-right.svg')" alt="">
            </span>
          </a>
        </v-col>
        <!-- END display large -->
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialogMenuMobile"
      fullscreen
      hide-overlay
      content-class="v-dialog--small"
      transition="dialog-top-transition"
    >
      <v-container class="navMobile__holder">
        <v-row justify="end" class="dialogHeader">
          <v-col cols="auto">
            <a @click="dialogMenuMobile = false" title="Fermer" class="link--close">
              Fermer
              <img :src="require('../../assets/images/icons/icon-close.svg')" alt="">
            </a>
          </v-col>
        </v-row>

        <div class="navMobile">
          <div class="navMobile__telto">
            <a
              @click="triggerTagClick({
                key: 'telephone_clic',
                label: '+33183101010',
              })"
              href="tel:+33183101010"
            >01 83 10 10 10</a>
            <p>
              Du lundi au vendredi
              <br/>
              de 9h à 18h (appel gratuit)
            </p>
          </div>

          <a
            class="navMobile__cta"
            v-ripple
            href="https://boutique.efl.fr/"
            title="boutique.elf.fr"
            target="_blank"
            @click="triggerTagClick({
              key: 'goToEflShop_clic',
              label: 'Accèder à la boutique',
            })"
          >
            <span class="text">Accèder à la boutique</span>
            <span class="icon">
              <img :src="require('@/assets/images/icons/icon-arrow-right-coral.svg')" alt="">
            </span>
          </a>

          <ul class="navMobile__link">
            <li><span>Copyright © {{ year }} Editions Francis Lefebvre</span></li>

            <li>
              <a
                @click="
                  $emit('openLegalMentions'),
                  dialogMenuMobile=false,
                  triggerTagClick({
                    key: 'legalMentions_clic',
                    label: 'Mentions légales',
                  })
                "
                >Mentions légales</a>
              </li>

            <li><a
              href="https://www.lefebvre-sarrut.eu/LS_Politique_de_confidentialite.pdf"
              target="_blank"
              @click="triggerTagClick({
                key: 'rgpd_clic',
                label: 'Politique de confidentialité des données',
              })"
            >Politique de confidentialité des données</a></li>
            <a href="javascript:Didomi.preferences.show()">Gérer mon consentement</a>
          </ul>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppHeader',

  data() {
    return {
      dialogMenuMobile: false,
      year: new Date().getFullYear(),
    };
  },

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },
};
</script>

<style lang="scss">
  @import 'AppHeader.scss';
</style>
