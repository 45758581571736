<template>
  <transition-group
    name="fadeTranslate"
    tag="div"
    @before-enter="beforeEnter"
    @before-leave="beforeLeave"
    @after-enter="afterEnter"
    :class="className"
    appear
  >
    <slot></slot>
  </transition-group>
</template>

<script>
export default {
  name: 'TransitionSequenced',

  props: [
    'className',
    'lockAnimation',
  ],

  methods: {
    beforeEnter(el) {
      if (this.animationChat) {
        const { style } = el;
        const { index } = el.dataset;

        style.transitionDelay = `${index * this.animationChatSpeed * 2}s`;
      }
    },

    beforeLeave(el) {
      if (this.animationChat) {
        const { style } = el;
        style.transitionDelay = '0s';
      }
    },

    afterEnter() {
      this.$emit('setFocus');

      if (!this.lockAnimation) {
        this.$emit('updateScroll');
      }
    },
  },

  computed: {
    animationChat() {
      return this.$store.state.Config.animationChat;
    },

    animationChatSpeed() {
      return this.$store.state.Config.animationChatSpeed;
    },
  },
};
</script>
