<template>
  <div class="mainContent" ref="scrollContentSmall">
    <div class="mainContent--left">
      <div class="inner">
        <h1 class="heroTitle" v-html="getText('common.landing.heroTitle')" />

        <div class="baseline" v-html="getText('common.landing.baseLine')" />

        <template v-if="!appIsStarted">
          <p
            v-for="(text, i) in getText('common.landing.descLong')"
            :key="`chatTextLeft-${i}`"
            v-html="text"
          />
        </template>

        <a
          v-if="appIsStarted"
          key="chatTextLeft--reset"
          class="button--reload"
          @click="dialogReset = true"
          v-ripple
        >
          <img
            :src="require('@/assets/images/icons/icon-reload.svg')" alt="">
          Recommencer la conversation
        </a>

        <transition name="fade">
          <productCardSelected
            v-if="productIsSelected && appIsStarted"
            @openForm="dialogForm = true"
          />
        </transition>
      </div>

      <div class="shape--separator">
        <img :src="require('@/assets/images/shape-wave-hztl.svg')" alt="">
      </div>
    </div>

    <div
      class="mainContent--right"
      :class="{ 'hasPaddingBtm': productIsSelected && appIsStarted}"
    >
      <chatBox
        @openForm="dialogForm = true"
        @openFormContact="openContact"
        @appStarted="appIsStarted = true"
        @mainProductSelect="productIsSelected = true"
        :refContentSmall="$refs.scrollContentSmall"
      />
    </div>

    <v-dialog
      v-model="dialogForm"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-container>
        <v-row justify="end" class="dialogHeader">
          <v-col cols="auto">
            <a @click="dialogForm = false" title="Fermer" class="link--close">
              Fermer
              <img :src="require('../../assets/images/icons/icon-close.svg')" alt="">
            </a>
          </v-col>
        </v-row>

        <formContent @closeDialog="dialogForm = false" />
      </v-container>
    </v-dialog>

    <v-dialog
      v-model="dialogFormContact"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-container>
        <v-row justify="end" class="dialogHeader">
          <v-col cols="auto">
            <a @click="dialogFormContact = false" title="Fermer" class="link--close">
              Fermer
              <img :src="require('../../assets/images/icons/icon-close.svg')" alt="">
            </a>
          </v-col>
        </v-row>

        <formContact
          @close="dialogFormContact = false"
          :index="dialogFormContactIndex"
        />
      </v-container>
    </v-dialog>

    <!-- reset app -->
    <dialogConfirmationAction
      :visible="dialogReset"
      width="500"
      content="Je souhaite recommencer la conversation depuis le début"
      @close="dialogReset = false"
      @confirm="resetApp"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChatBox from '../chatBox/ChatBox.vue';
import ProductCardSelected from '../productCardSelected/ProductCardSelected.vue';
import FormContent from '../../pages/formContent/FormContent.vue';
import FormContact from '../../pages/formContent/FormContact.vue';
import DialogConfirmationAction from '../dialogConfirmationAction/DialogConfirmationAction.vue';

export default {
  name: 'AppContent',

  components: {
    ChatBox,
    ProductCardSelected,
    FormContent,
    FormContact,
    DialogConfirmationAction,
  },

  data() {
    return {
      appIsStarted: false,
      productIsSelected: false,
      dialogForm: false,
      dialogReset: false,
      dialogFormContact: false,
      dialogFormContactIndex: 0,
    };
  },

  methods: {
    resetApp() {
      this.dialogReset = false;

      // TODO : better app reset to brutal, keep only for dev
      window.location.reload();
    },

    openContact(index) {
      this.dialogFormContact = true;
      this.dialogFormContactIndex = index;
    },
  },

  computed: {
    ...mapGetters([
      'getText',
    ]),
  },
};
</script>

<style lang="scss">
  @import 'AppContent.scss';
</style>
