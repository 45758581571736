import {
  configApiLead as Repository,
} from './config';

export default {
  getUuid() {
    return Repository().get('/uuid');
  },

  postUser(body, headers) {
    return Repository(headers).post(
      '/configurateur/submit',
      body,
    );
  },
};
