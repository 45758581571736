var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getMainProduct)?_c('div',{staticClass:"productCardSelected"},[_c('div',{staticClass:"productCardSelected__header"},[_vm._v("Notre recommandation n°1")]),_c('div',{staticClass:"productCardSelected__title"},[_c('span',{staticClass:"emoji"},[_vm._v("🥇")]),_vm._v(" "+_vm._s(_vm.getMainProduct.name)+" "),(_vm.getMainProduct.subtitle)?_c('span',{domProps:{"innerHTML":_vm._s((" - " + (_vm.getMainProduct.subtitle)))}}):_vm._e()]),_c('div',{staticClass:"productCardSelected__content"},[_c('div',{staticClass:"productCardSelected__col"},[_c('div',{staticClass:"productImage"},[_c('img',{attrs:{"src":_vm.getMainProduct.image_url,"alt":_vm.getMainProduct.name}})]),_c('a',{staticClass:"link--alt",attrs:{"href":_vm.getMainProduct.product_url,"target":"_blank","title":"Voir plus d’infos"},on:{"click":function($event){return _vm.triggerTagClick({
          key: 'infos_produit1',
          label: 'Voir plus d’infos',
        })}}},[_vm._v("Voir plus d’infos")])]),_c('div',{staticClass:"productCardSelected__col"},[_c('priceBox',{attrs:{"price":_vm.getMainProduct.final_price || _vm.getMainProduct.original_price}})],1),_c('div',{staticClass:"productCardSelected__col"},[(_vm.cta === 1)?[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"chatItem__button coral",on:{"click":function($event){_vm.$emit('openForm'),
            _vm.triggerTagClick({
              key: 'eg_clic_gauche',
              label: 'Je débute mon essai gratuit de 10 jours',
            })}}},[_vm._v(" Je débute mon essai gratuit de 10 jours ")])]:_vm._e(),(_vm.cta === 2)?[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"chatItem__button coral",attrs:{"href":_vm.$store.state.Config.linkExternalMementis,"target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
            key: 'eg_clic_gauche',
            label: 'Je teste gratuitement',
          })}}},[_vm._v("Je teste gratuitement")])]:_vm._e(),(_vm.cta === 3 || _vm.cta === 4)?[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"chatItem__button coral",attrs:{"href":_vm.getMainProduct.product_url,"target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
            key: 'eg_clic_gauche',
            label: 'Je découvre',
          })}}},[_vm._v("Je découvre")])]:_vm._e()],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }