<template>
  <div class="productSelected">
    <div class="productSelected__title">
      <span class="emoji">&#x1F947;</span>
      {{ getMainProduct.name }}
      <span
        v-if="getMainProduct.subtitle"
        v-html="` - ${getMainProduct.subtitle}`"
      ></span>
    </div>

    <div class="productSelected__desc d-none d-md-block">
      <div class="productImage">
        <img :src="getMainProduct.image_url" :alt="getMainProduct.name" />
      </div>

      <priceBox
        :invert="true"
        :price="getMainProduct.final_price || getMainProduct.original_price"
      />

      <a
        class="link--alt"
        :href="getMainProduct.product_url"
        target="_blank"
        title="Voir plus d’infos"
      >Voir plus d’infos</a>
    </div>

    <div class="productSelected__attributes d-none d-md-block">
      <!-- <div class="item">
        <span>icon api</span>
        <p>Un fond documentaire juridique pluridisciplinaire mis
          à jour en continu</p>
      </div> -->

      <div class="item" v-html="getMainProduct.description_api"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PriceBox from '../../../components/priceBox/PriceBox.vue';

export default {
  name: 'FormSideBar',

  components: {
    PriceBox,
  },

  computed: {
    ...mapGetters([
      'getMainProduct',
    ]),
  },
};
</script>
