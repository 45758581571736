<template>
  <div class="avatar">
    <img :src="require(`../../assets/images/avatar/${fileName}`)">
  </div>
</template>

<script>
export default {
  name: 'Avatar',

  props: [
    'fileName',
  ],
};
</script>

<style lang="scss">
  @import 'Avatar.scss';
</style>
