<template>
  <div class="appFooter d-none d-md-block">
    <v-container fluid>
      <v-row align="center">
        <v-col>
          <ul>
            <li><span>Copyright © {{ year }} Editions Francis Lefebvre</span></li>
            <li><a
              @click="
                $emit('openLegalMentions'),
                triggerTagClick({
                  key: 'legalMentions_clic',
                  label: 'Mentions légales',
                })
              "
              >Mentions légales</a>
            </li>
            <li><a
              :href="urlRGPD"
              target="_blank"
              @click="triggerTagClick({
                key: 'rgpd_clic',
                label: 'Politique de confidentialité des données',
              })"
              >Politique de confidentialité des données</a>
            </li>

            <li>
              <a href="javascript:Didomi.preferences.show()">Gérer mon consentement</a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppFooter',

  data() {
    return {
      year: new Date().getFullYear(),
    };
  },

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },

  computed: {
    urlRGPD() {
      return this.$store.state.Config.linkEternalRGPD;
    },
  },
};
</script>

<style lang="scss">
  @import 'AppFooter.scss';
</style>
