import { configFile as Repository } from './config';

export default {
  get() {
    return Repository().get(
      process.env.VUE_APP_EFL_DATA_XLSX_FILE,
      {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        responseType: 'arraybuffer',
      },
    );
  },
};
