<template>
  <div
    v-if="getMainProduct"
    class="productCardSelected"
  >
    <div class="productCardSelected__header">Notre recommandation n°1</div>

    <div class="productCardSelected__title">
      <span class="emoji">&#x1F947;</span>
      {{ getMainProduct.name }}
      <span
        v-if="getMainProduct.subtitle"
        v-html="` - ${getMainProduct.subtitle}`"
      ></span>
    </div>

    <div class="productCardSelected__content">
      <div class="productCardSelected__col">
        <div class="productImage">
          <img :src="getMainProduct.image_url" :alt="getMainProduct.name" />
        </div>

        <a
          class="link--alt"
          @click="triggerTagClick({
            key: 'infos_produit1',
            label: 'Voir plus d’infos',
          })"
          :href="getMainProduct.product_url"
          target="_blank"
          title="Voir plus d’infos"
        >Voir plus d’infos</a>
      </div>

      <div class="productCardSelected__col">
        <priceBox
          :price="getMainProduct.final_price || getMainProduct.original_price"
        />
      </div>

      <div class="productCardSelected__col">
        <!-- case 1 -->
        <template v-if="cta === 1">
          <a
            @click="
              $emit('openForm'),
              triggerTagClick({
                key: 'eg_clic_gauche',
                label: 'Je débute mon essai gratuit de 10 jours',
              })"
            class="chatItem__button coral"
            v-ripple
          >
            Je débute mon essai gratuit de 10 jours
          </a>
        </template>
        <!-- case 2 -->
        <template v-if="cta === 2">
          <a
            :href="$store.state.Config.linkExternalMementis"
            @click="triggerTagClick({
              key: 'eg_clic_gauche',
              label: 'Je teste gratuitement',
            })"
            target="_blank"
            class="chatItem__button coral"
            v-ripple
          >Je teste gratuitement</a>
        </template>
        <!-- case 3 -->
        <!-- case 4 -->
        <template v-if="cta === 3 || cta === 4">
          <a
            :href="getMainProduct.product_url"
            target="_blank"
            class="chatItem__button coral"
            v-ripple
            @click="triggerTagClick({
              key: 'eg_clic_gauche',
              label: 'Je découvre',
            })"
          >Je découvre</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PriceBox from '../priceBox/PriceBox.vue';

export default {
  name: 'ProductCardSelected',

  data() {
    return {
      match1: /Navis|INNEO|Focus Expert-comptable/g,
      match2: /Mémentis/g,
      match3: /Logiciels|Services/g,
      cta: 1,
    };
  },

  components: {
    PriceBox,
  },

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),

    updateDisplay() {
      if (this.getMainProduct.categories) {
        if (this.getMainProduct.categories.match(this.match1)) {
          this.cta = 1;
        } else if (this.getMainProduct.categories.match(this.match2)) {
          this.cta = 2;
        } else if (this.getMainProduct.categories.match(this.match3)) {
          this.cta = 3;
        } else {
          this.cta = 4;
        }
      } else {
        this.cta = 4;
      }
    },
  },

  computed: {
    ...mapGetters([
      'getMainProduct',
    ]),
  },

  mounted() {
    this.updateDisplay();
  },
};
</script>

<style lang="scss">
  @import 'ProductCardSelected.scss';
</style>
