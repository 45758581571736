<template>
  <div class="chatItem__card">
    <transition name="fade" mode="out-in">
      <loaderDot v-if="loader" :color="loaderColor" />
      <template v-else>
        <slot></slot>
      </template>
    </transition>
  </div>
</template>

<script>
import LoaderDot from '../loaderDot/LoaderDot.vue';

export default {
  name: 'ChatItemCard',

  props: [
    'delay',
  ],

  data() {
    return {
      loader: true,
      loaderColor: '#002256',
    };
  },

  components: {
    LoaderDot,
  },

  mounted() {
    if (this.animationChat) {
      setTimeout(() => {
        this.loader = false;
      }, this.delay * 2000 + 1000);
    } else {
      this.loader = false;
    }
  },

  computed: {
    animationChat() {
      return this.$store.state.Config.animationChat;
    },
  },
};
</script>
