<template>
  <div>
    <chatItem
      :hideAnswer="true"
      :readOnly="formIsReadOnly"
    >
      <template v-slot:content>
        <transitionSequenced
          className="chatItem__content"
          @updateScroll="updateScroll"
        >
          <chatItemCard
            key="q0-label-0"
            delay="0"
            :data-index="0"
          >
            <span>
              <span v-html="getText('common.intro')[0]" />
            </span>
          </chatItemCard>

          <chatItemCard
            key="q0-label-1"
            delay="1"
            :data-index="1"
          >
            <span v-html="getText('common.intro')[1]" />
          </chatItemCard>

          <a
            key="q0-label-2"
            :data-index="2"
            v-if="!appIsStarted"
            @click="$emit('appStarted'),
              appIsStarted = true,
              question1isVisible = true,
              triggerTagClick({
                key: 'conversation_start',
                label: 'Oui, commençons',
              })
            "
            class="button small-L"
            v-ripple
          >
            <img class="icon--prepend"
              :src="require('../../assets/images/icons/icon-heart.svg')">
            Oui, commençons !
          </a>
        </transitionSequenced>
      </template>
    </chatItem>

    <!-- Question 1 : métier -->
    <transition name="fade-delay">
      <chatItem
        v-if="question1isVisible"
        :answer="getAnswerLabel('question_1', answerQuestion1)"
        @edit="question1isLock = false, scrollTo('chatItem--metier')"
        :readOnly="formIsReadOnly"
        ref="chatItem--metier"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            :lockAnimation="question1isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_1')[0].label">
              <chatItemCard
                :key="`q1-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question1isLock"
              class="chatItem__content__question"
              key="q1-question-0"
              :data-index="question1isDirty ? 0 : getQuestion('question_1')[0].label.length"
            >
              <div class="chatItem__label" v-html="getQuestion('question_1')[0].labelAnswer"/>

              <div class="chatItem__action">
                <div class="chatItem__action__radioGroup">
                  <v-radio-group
                    v-model="answerQuestion1"
                    @change="
                      updateFunction($event),
                      triggerTagClick({
                        key: 'function_clic',
                        label: $event,
                      })
                    "
                  >
                    <v-radio
                      v-for="answer in getQuestion('question_1')[0].answers"
                      :key="answer.id"
                      :label="answer.label"
                      :value="answer.id"
                      v-ripple
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_1')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- Question 2 : missions -->
    <transition name="fade-delay">
      <chatItem
        v-if="question2isVisible"
        :answer="question2isDirty ? getAnswerLabels('question_2', answerQuestion2) : ''"
        @edit="question2isLock = false, scrollTo('chatItem--missions')"
        :readOnly="formIsReadOnly"
        ref="chatItem--missions"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            :lockAnimation="question2isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_2')[0].label">
              <chatItemCard
                :key="`q2-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question2isLock"
              class="chatItem__content__question"
              key="q2-question-0"
              :data-index="question2isDirty ? 0 : getQuestion('question_2')[0].label.length"
            >
              <div class="chatItem__label" v-html="getQuestion('question_2')[0].labelAnswer"/>

              <div class="chatItem__action">
                <div class="chatItem__action__checkboxes">
                  <v-checkbox
                    v-model="answerQuestion2"
                    v-for="answer in getQuestion('question_2')[0].answers"
                    :key="answer.id"
                    :label="answer.label"
                    :value="answer.id"
                    hide-details
                    v-ripple
                  ></v-checkbox>
                </div>
              </div>
            </div>

            <a
              v-if="!question2isLock"
              key="q2-question-1"
              :data-index="question2isDirty ? 0 : getQuestion('question_2')[0].label.length"
              @click="submitQuestion2"
              v-ripple
              class="button small"
              style="margin-top: 43px;"
            >
              Je valide ces missions
            </a>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_2')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- Question 3 : taille -->
    <transition name="fade-delay">
      <chatItem
        v-if="question3isVisible"
        :answer="getAnswerLabel('question_3', answerQuestion3)"
        @edit="question3isLock = false, scrollTo('chatItem--taille')"
        :readOnly="formIsReadOnly"
        ref="chatItem--taille"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            :lockAnimation="question3isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_3')[0].label">
              <chatItemCard
                :key="`q3-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question3isLock"
              class="chatItem__content__question"
              key="q3-question-0"
              :data-index="question3isDirty ? 0 : getQuestion('question_3')[0].label.length"
            >
              <div class="chatItem__label" v-html="getQuestion('question_3')[0].labelAnswer"/>

              <div class="chatItem__action">
                <div class="chatItem__action__radioGroup">
                  <v-radio-group
                    v-model="answerQuestion3"
                    @change="
                      updateSize($event),
                      triggerTagClick({
                        key: 'effectif_clic',
                        label: $event,
                      })
                    "
                  >
                    <v-radio
                      v-for="answer in getQuestion('question_3')[0].answers"
                      :key="answer.id"
                      :label="answer.label"
                      :value="answer.id"
                      v-ripple
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_3')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- Question 4 : solution -->
    <transition name="fade-delay">
      <chatItem
        v-if="question4isVisible"
        :answer="getAnswer4()"
        @edit="question4isLock = false,
          question4HidePrefixAnswer = false,
          scrollTo('chatItem--solution')"
        :readOnly="formIsReadOnly"
        ref="chatItem--solution"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            :lockAnimation="question4isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_4')[0].label">
              <chatItemCard
                :key="`q4-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question4isLock"
              class="chatItem__content__question"
              key="q4-question-0"
              :data-index="question4isDirty ? 0 : getQuestion('question_4')[0].label.length"
            >
              <a
                @click="question4undefined"
                v-ripple
                class="chatItem__button sizeAuto"
              >
                <span>
                  Non absolument pas (ou je ne comprends pas la question
                  <span class="emoji">&#x1F642;</span>)
                </span>
              </a>

              <div class="chatItem__label" v-html="getQuestion('question_4')[0].labelAnswer"/>

              <div class="chatItem__action">
                <div class="chatItem__action__radioGroup">
                  <v-radio-group
                    v-model="answerQuestion4"
                    @change="
                      updateSolution($event),
                      triggerTagClick({
                        key: 'solution_clic',
                        label: $event,
                      })
                    "
                  >
                    <v-radio
                      v-for="answer in getQuestion('question_4')[0].answers"
                      :key="answer.id"
                      :label="answer.label"
                      :value="answer.id"
                      v-ripple
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span
            v-html="question4HidePrefixAnswer ? '' : getQuestion('question_4')[0].prefixAnswer"
          />
        </template>
      </chatItem>
    </transition>

    <!-- Question 5 : prenom -->
    <transition name="fade-delay">
      <chatItem
        v-if="question5isVisible"
        :answer="getFirstName && question5isDirty ? getFirstName : ''"
        @edit="question5isLock = false, scrollTo('chatItem--prenom')"
        :readOnly="formIsReadOnly"
        ref="chatItem--prenom"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            @setFocus="$refs.formChatBoxFirstName.focus()"
            :lockAnimation="question5isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_5')[0].label">
              <chatItemCard
                :key="`q5-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question5isLock"
              class="chatItem__content__question"
              key="q5-question-0"
              :data-index="question5isDirty ? 0 : getQuestion('question_5')[0].label.length"
            >
              <div class="chatItem__action">
                <v-form
                  ref="formFirstName"
                  @submit.prevent
                  onSubmit="return false;"
                  lazy-validation
                >
                  <div class="chatItem__action__inputText">
                    <v-text-field
                      ref="formChatBoxFirstName"
                      label="Saisissez votre prénom ici"
                      outlined
                      :value="getFirstName"
                      :rules="rulesRequiredLength()"
                      @input="updateFirstName($event)"
                      @keyup.enter.prevent="
                        validate('formFirstName', 5),
                        triggerTagClick({
                          key: 'firstname_clic',
                          label: getFirstName,
                        })
                      "
                    ></v-text-field>

                    <a
                      @click="
                        validate('formFirstName', 5),
                        triggerTagClick({
                          key: 'firstname_clic',
                          label: getFirstName,
                        })
                      "
                      v-ripple
                      class="button small"
                    >Ok</a>
                  </div>
                </v-form>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_5')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- Question 6 : nom -->
    <transition name="fade-delay">
      <chatItem
        v-if="question6isVisible"
        :answer="getLastName && question6isDirty ? getLastName : ''"
        @edit="question6isLock = false, scrollTo('chatItem--nom')"
        :readOnly="formIsReadOnly"
        ref="chatItem--nom"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            @setFocus="$refs.formChatBoxLastName.focus()"
            :lockAnimation="question6isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_6')[0].label">
              <chatItemCard
                :key="`q6-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question6isLock"
              class="chatItem__content__question"
              key="q6-question-0"
              :data-index="question6isDirty ? 0 : getQuestion('question_6')[0].label.length"
            >
              <div class="chatItem__action">
                <v-form
                  ref="formLastName"
                  @submit.prevent
                  onSubmit="return false;"
                  lazy-validation
                >
                  <div class="chatItem__action__inputText">
                    <v-text-field
                      outlined
                      ref="formChatBoxLastName"
                      label="Saisissez votre nom ici"
                      :value="getLastName"
                      :rules="rulesRequiredLength()"
                      @input="updateLastName($event)"
                      @keyup.enter.prevent="
                        validate('formLastName', 6),
                        triggerTagClick({
                          key: 'name_clic',
                          label: getLastName,
                        })
                      "
                    ></v-text-field>

                    <a
                      @click="
                        validate('formLastName', 6),
                        triggerTagClick({
                          key: 'name_clic',
                          label: getLastName,
                        })
                      "
                      v-ripple
                      class="button small"
                    >Ok</a>
                  </div>
                </v-form>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_6')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- Question 7 : email -->
    <transition name="fade-delay">
      <chatItem
        v-if="question7isVisible"
        :answer="getEmail && question7isDirty ? getEmail : ''"
        @edit="question7isLock = false, scrollTo('chatItem--email')"
        :readOnly="formIsReadOnly"
        ref="chatItem--email"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
            @setFocus="$refs.formChatBoxEmail.focus()"
            :lockAnimation="question7isDirty"
          >
            <template v-for="(label, i) in getQuestion('question_7')[0].label">
              <chatItemCard
                :key="`q7-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question7isLock"
              class="chatItem__content__question"
              key="q7-question-0"
              :data-index="question7isDirty ? 0 : getQuestion('question_7')[0].label.length"
            >
              <div class="chatItem__action">
                <v-form
                  ref="formEmail"
                  @submit.prevent
                  onSubmit="return false;"
                  lazy-validation
                >
                  <div class="chatItem__action__inputText">
                    <v-text-field
                      outlined
                      ref="formChatBoxEmail"
                      label="Saisissez votre email ici"
                      type="email"
                      :value="getEmail"
                      :rules="rulesRequiredLengthEmail()"
                      @input="updateEmail($event)"
                      @keyup.enter.prevent="
                        validate('formEmail', 7),
                        triggerTagClick({
                          key: 'email_clic',
                          label: getEmail,
                        })
                      "
                    ></v-text-field>

                    <a
                      @click="
                        validate('formEmail', 7),
                        triggerTagClick({
                          key: 'email_clic',
                          label: getEmail,
                        })
                      "
                      v-ripple
                      class="button small"
                    >Ok</a>
                  </div>
                  <p class="chatItem__info">
                    En cliquant sur OK, j'accepte que les informations saisies soient utilisées
                    par EFL, dans le cadre de notre
                    <a :href="urlRGPD" target="_blank">
                      politique de gestion des données personnelles
                    </a>
                  </p>
                </v-form>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_7')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- 8 : product -->
    <transition name="fade-delay">
      <chatItem
        :hideAnswer="true"
        v-if="productIsVisible"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
          >
            <template v-for="(label, i) in getQuestion('question_8')[0].label">
              <chatItemCard
                :key="`q8-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span>
                  <span class="emoji">&#x1F38A;</span>
                  <span v-html="label" />
                  <span class="emoji">&#x1F38A;</span>
                </span>
              </chatItemCard>
            </template>

            <!-- <div class="chatItem__card full"> -->
            <chatItemCard
              key="q8-label-1"
              delay="1"
              :data-index="1"
            >
              <productCard :product="getMainProduct" />
            </chatItemCard>

            <div
              key="q8-label-2"
              delay="2"
              :data-index="2"
              style="width: 100%;"
            >
              <productCardActions
                @openForm="$emit('openForm')"
                @openFormContact="
                  $emit('openFormContact'),
                  triggerTagView({
                    key: 'formcontact1_view',
                  })
                "
                :categories="getMainProduct.categories"
                :url="getMainProduct.product_url"
              />
            </div>
          </transitionSequenced>
        </template>
      </chatItem>
    </transition>

    <!-- 9 : product related question -->
    <transition name="fade-delay">
      <chatItem
        v-if="question9isVisible"
        :answer="getAnswerLabel('question_9', answerQuestion9)"
        :readOnly="formIsReadOnly"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
          >
            <template v-for="(label, i) in getQuestion('question_9')[0].label">
              <chatItemCard
                :key="`q9-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question9isLock"
              class="chatItem__content__question"
              key="q9-question-0"
              :data-index="question9isDirty ? 0 : getQuestion('question_9')[0].label.length"
            >
              <div class="chatItem__action">
                <div class="chatItem__action__radioGroup widthAuto">
                  <v-radio-group
                    v-model="answerQuestion9"
                    @change="triggerTagClick({
                      key: 'autres_solutions_clic',
                      label: $event,
                    })"
                  >
                    <v-radio
                      v-for="answer in getQuestion('question_9')[0].answers"
                      :key="answer.id"
                      :label="answer.label"
                      :value="answer.id"
                      v-ripple
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_9')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>

    <!-- 9.bis : product related question -->
    <transition name="fade-delay">
      <chatItem
        v-if="relatedProductIsVisible && relatedProductIsLoaded"
        :hideAvatar="true"
        :hideAnswer="true"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content grid-3"
            @updateScroll="updateScroll"
          >
            <div
              v-for="(product, i) in getRelatedProducts"
              :key="product.sku"
              class="grid-col"
            >
              <div class="chatItem__card">
                <productCardRelated
                  :product="product"
                  :index="i + 2"
                />
              </div>

              <a
                class="chatItem__button"
                v-ripple
                @click.stop="
                  $emit('openFormContact', i + 2),
                  triggerTagClick({
                    key: `contact_produit${i + 2}`,
                    label: 'Mettez moi en contact avec un conseiller',
                  }),
                  triggerTagView({
                    key: `formcontact${i + 2}_view`,
                  })
                "
              >
                Mettez moi en contact avec un conseiller
              </a>
            </div>
          </transitionSequenced>
        </template>
      </chatItem>
    </transition>

    <!-- 10 : finish / contact -->
    <transition name="fade-delay">
      <chatItem
        v-if="question10isVisible"
        :answer="getAnswerLabel('question_10', answerQuestion10)"
        @edit="question10isLock = false"
        :readOnly="formIsReadOnly"
      >
        <template v-slot:content>
          <transitionSequenced
            className="chatItem__content"
            @updateScroll="updateScroll"
          >
            <template v-for="(label, i) in getQuestion('question_10')[0].label">
              <chatItemCard
                :key="`q10-label-${i}`"
                :delay="i"
                :data-index="i"
              >
                <span v-html="label" />
              </chatItemCard>
            </template>

            <div
              v-if="!question10isLock"
              class="chatItem__actions widthAuto noWrap"
              key="q10-question-0"
              :data-index="question10isDirty ? 0 : getQuestion('question_10')[0].label.length"
            >
              <template
                v-for="answer in getQuestion('question_10')[0].answers"
              >
                <a
                  :key="`q10-answer-${answer.id}`"
                  :class="`chatItem__button q10-answer-${answer.id}`"
                  v-ripple
                  v-html="answer.label"
                  @click="
                    chatActionQ10(answer.id),
                    triggerTagClick({
                      key: 'reponse_attente',
                      label: answer.id,
                    })
                  "
                />
              </template>
            </div>
          </transitionSequenced>
        </template>

        <template v-slot:answer>
          <span v-html="getQuestion('question_10')[0].prefixAnswer" />
        </template>
      </chatItem>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ChatItem from '../chatItem/ChatItem.vue';
import ChatItemCard from '../chatItemCard/ChatItemCard.vue';
import TransitionSequenced from '../transition/TransitionSequenced.vue';
import ProductCard from '../productCard/ProductCard.vue';
import ProductCardRelated from '../productCardRelated/ProductCardRelated.vue';
import ProductCardActions from '../productCardActions/ProductCardActions.vue';
import {
  rulesRequiredLength,
  rulesRequiredLengthEmail,
} from '../../plugins/validator';

export default {
  name: 'ChatContent',

  components: {
    ChatItem,
    ChatItemCard,
    TransitionSequenced,
    ProductCard,
    ProductCardRelated,
    ProductCardActions,
  },

  data() {
    return {
      appIsStarted: false,
      formIsReadOnly: false,
      // animation delay
      animationDelay: 500,
      // métier
      question1isVisible: false,
      answerQuestion1: '',
      question1isLock: false,
      question1isDirty: false,
      // missions
      question2isVisible: false,
      answerQuestion2: [],
      question2isLock: false,
      question2isDirty: false,
      // taille
      question3isVisible: false,
      answerQuestion3: '',
      question3isLock: false,
      question3isDirty: false,
      // solution
      question4isVisible: false,
      answerQuestion4: '',
      question4isLock: false,
      question4isDirty: false,
      question4HidePrefixAnswer: true,
      // prenom
      question5isVisible: false,
      question5isLock: false,
      question5isDirty: false,
      // nom
      question6isVisible: false,
      question6isLock: false,
      question6isDirty: false,
      // email
      question7isVisible: false,
      question7isLock: false,
      question7isDirty: false,
      // product
      productIsVisible: false,
      // product related
      question9isVisible: false,
      relatedProductIsVisible: false,
      relatedProductIsLoaded: false,
      answerQuestion9: '',
      question9isLock: false,
      question9isDirty: false,
      // question 10
      question10isVisible: false,
      answerQuestion10: '',
      question10isLock: false,
      question10isDirty: false,
    };
  },

  methods: {
    rulesRequiredLength,
    rulesRequiredLengthEmail,

    ...mapActions([
      'setProgressBar',
      'updateFilteredProduct',
      'setMainProduct',
      'setRelatedProducts',
      'updateFirstName',
      'updateLastName',
      'updateEmail',
      'updateFunction',
      'updateMissions',
      'updateSize',
      'updateSolution',
      'postUser',
      'triggerTagClick',
      'triggerTagView',
    ]),

    validate(ref, step) {
      const formIsValid = this.$refs[ref] ? this.$refs[ref].validate() : false;

      if (formIsValid) {
        this[`question${step}isLock`] = true;
        this[`question${step}isDirty`] = true;
        this[`question${step + 1}isVisible`] = true;
        this.updateProgressBar(step);

        // 7 is submit step
        if (step === 7) {
          this.loadProduct();
        }
      }
    },

    updateScroll() {
      this.$emit('contentUpdate');
    },

    scrollTo(target) {
      const chatItem = this.$refs[target].$el;
      chatItem.scrollIntoView({
        behavior: 'smooth',
      });
    },

    updateProgressBar(step) {
      const totalStep = 7;
      const progress = (step * 100) / totalStep;

      this.setProgressBar(progress);
    },

    submitQuestion2() {
      if (this.answerQuestion2.length > 0) {
        this.question2isLock = true;
        this.question2isDirty = true;
        this.updateProgressBar(2);

        this.triggerTagClick({
          key: 'poi_clic',
          label: this.answerQuestion2,
        });

        setTimeout(() => {
          this.question3isVisible = true;
        }, this.animationDelay);
      }
    },

    question4undefined() {
      this.answerQuestion4 = undefined;
      this.question4HidePrefixAnswer = true;
      this.question4isLock = true;
      this.question4isDirty = true;
      this.updateProgressBar(4);

      setTimeout(() => {
        this.question5isVisible = true;
      }, this.animationDelay);
    },

    getAnswer4() {
      if (this.question4isDirty) {
        return this.getAnswerLabel('question_4', this.answerQuestion4) || 'Non';
      }

      return this.getAnswerLabel('question_4', this.answerQuestion4) || this.answerQuestion4;
    },

    loadProduct() {
      const params = [
        parseInt(this.answerQuestion1, 10),
        this.answerQuestion2,
        this.answerQuestion4,
        this.answerQuestion3,
      ];
      let mainId;
      let relatedId;

      this.updateFilteredProduct(params);

      if (this.answerQuestion4) {
        mainId = this.getProductsIds.mainId;
        relatedId = this.getProductsIds.relatedId;
      } else {
        mainId = this.getProductsIdsNoSolution.mainId;
        relatedId = this.getProductsIdsNoSolution.relatedId;
      }

      this.setMainProduct(mainId).then(() => {
        this.productIsVisible = true;
        this.formIsReadOnly = true;

        this.postUser({ isEG: false });

        setTimeout(() => {
          this.$emit('mainProductSelect');
        }, this.animationChatSpeed * 3000);
      });

      this.setRelatedProducts(relatedId).then(() => {
        this.relatedProductIsLoaded = true;
      });
    },

    chatActionQ10(id) {
      if (id === 'yes') {
        this.answerQuestion10 = id;
      } else {
        this.$emit('openFormContact');
      }
    },
  },

  computed: {
    ...mapGetters([
      'getText',
      'getQuestion',
      'getAnswerLabel',
      'getAnswerLabels',
      'getProductsIds',
      'getMainProduct',
      'getRelatedProducts',
      'getFirstName',
      'getLastName',
      'getEmail',
      'getFunction',
      'getProductsListFiltered',
      'getProductsIdsNoSolution',
    ]),

    urlRGPD() {
      return this.$store.state.Config.linkEternalRGPD;
    },

    animationChatSpeed() {
      return this.$store.state.Config.animationChatSpeed;
    },

    linkExternalCalendar() {
      return this.$store.state.Config.linkExternalCalendar;
    },
  },

  watch: {
    // question 1
    answerQuestion1() {
      this.question1isLock = true;
      this.question1isDirty = true;
      this.updateProgressBar(1);

      setTimeout(() => {
        this.question2isVisible = true;
      }, this.animationDelay);
    },

    // question 2
    answerQuestion2(val) {
      this.updateMissions(val);
    },

    // question 3
    answerQuestion3() {
      this.question3isLock = true;
      this.question3isDirty = true;
      this.updateProgressBar(3);

      setTimeout(() => {
        this.question4isVisible = true;
      }, this.animationDelay);
    },

    // question 4
    answerQuestion4() {
      this.question4isLock = true;
      this.question4isDirty = true;
      this.updateProgressBar(4);

      setTimeout(() => {
        this.question5isVisible = true;
      }, this.animationDelay);
    },

    // product
    productIsVisible() {
      this.triggerTagView({
        key: 'pageproposal1_view',
      });

      setTimeout(() => {
        this.question9isVisible = true;
        this.updateScroll();
      }, 6000);
    },

    // question 9
    answerQuestion9() {
      this.question9isLock = true;
      this.question9isDirty = true;

      if (this.answerQuestion9 === 'yes') {
        setTimeout(() => {
          this.relatedProductIsVisible = true;
        }, this.animationDelay);
      } else {
        this.question10isVisible = true;
        this.updateScroll();
      }
    },

    relatedProductIsVisible() {
      this.triggerTagView({
        key: 'pageproposal2_view',
      });

      if (this.relatedProductIsVisible) {
        setTimeout(() => {
          this.question10isVisible = true;
        }, 3000);
      }
    },

    // question 10
    answerQuestion10() {
      this.question10isLock = true;
      this.question10isDirty = true;
    },
  },

  mounted() {
    // reset progress
    this.setProgressBar(0);
  },
};
</script>
