import Vue from 'vue';

const ProgressBar = {
  state: {
    progress: 0,
  },

  getters: {
    getProgress: (state) => state.progress,
  },

  mutations: {
    SET_PROGRESS: (state, payload) => {
      Vue.set(state, 'progress', payload);
    },
  },

  actions: {
    setProgressBar: ({ commit }, val) => {
      commit('SET_PROGRESS', val);
    },
  },
};

export default ProgressBar;
