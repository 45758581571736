<template>
  <v-app>
    <appHeader @openLegalMentions="dialogLegalMention = true" />

    <progressBar :value="getProgress" />

    <transition name="fade" mode="out-in">
      <v-main v-if="appIsLoaded && uuidIsLoaded">
        <appContent />
      </v-main>

      <div v-else class="globalLoader">
        <v-progress-circular
          indeterminate
          color="white"
          size="100"
          width="10"
        ></v-progress-circular>
      </div>
    </transition>

    <appFooter @openLegalMentions="dialogLegalMention = true" />

    <v-dialog
      v-model="dialogLegalMention"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-container>
        <v-row justify="end" class="dialogHeader">
          <v-col cols="auto">
            <a @click="dialogLegalMention = false" title="Fermer" class="link--close">
              Fermer
              <img :src="require('./assets/images/icons/icon-close.svg')" alt="">
            </a>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <legalMentions />
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppHeader from './components/appHeader/AppHeader.vue';
import AppContent from './components/appContent/AppContent.vue';
import AppFooter from './components/appFooter/AppFooter.vue';
import ProgressBar from './components/progressBar/ProgressBar.vue';
import LegalMentions from './pages/legalMentions/LegalMentions.vue';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppContent,
    AppFooter,
    ProgressBar,
    LegalMentions,
  },

  data: () => ({
    dialogLegalMention: false,
    appIsLoaded: false,
    uuidIsLoaded: false,
  }),

  methods: {
    ...mapActions([
      'fetchProductList',
      'fetchAppText',
      'fetchUserIds',
      'triggerTagView',
    ]),

    // import data
    importProductsList() {
      this.fetchProductList().then(() => {
        this.fetchAppText().then(() => {
          this.appIsLoaded = true;
          this.triggerTagView({
            key: 'home_view',
          });
        });
      });

      this.fetchUserIds().then(() => {
        this.uuidIsLoaded = true;
      });
    },
  },

  computed: {
    ...mapGetters([
      'getText',
      'getProgress',
      'getUuid',
    ]),
  },

  created() {
    this.importProductsList();
  },
};
</script>
