import axios from 'axios';

// CONFIG STATIC FILES
const baseUrlFile = process.env.VUE_APP_EFL_SERVER_FILE_BASEURL || '';
const filePath = process.env.VUE_APP_EFL_SERVER_FILE_PATH || '';

export function configFile(params) {
  return axios.create({
    baseURL: baseUrlFile + filePath,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    params,
  });
}

// CONFIG EFL PRODUCT API
const baseUrlApi = process.env.VUE_APP_EFL_SERVER_API_BASEURL || '';
const apiPath = process.env.VUE_APP_EFL_SERVER_API_PATH || '';
const bearerToken = process.env.VUE_APP_EFL_SERVER_API_KEY || '';

export function configApiProduct(params) {
  return axios.create({
    baseURL: baseUrlApi + apiPath,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Access-Control-Allow-Origin': '*',
    },
    params,
  });
}

// CONFIG BDD DBM STORE LEAD
const baseUrlApiLead = process.env.VUE_APP_LEAD_API_BASEURL || '';
const apiPathLead = process.env.VUE_APP_LEAD_API_PATH || '';
const bearerTokenLead = process.env.VUE_APP_LEAD_API_KEY || '';

export function configApiLead(params) {
  const headers = {
    Authorization: `Bearer ${bearerTokenLead}`,
    'Access-Control-Allow-Origin': '*',
    ...params,
  };

  return axios.create({
    baseURL: baseUrlApiLead + apiPathLead,
    headers,
  });
}
