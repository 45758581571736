<template>
  <div class="wysiwyg">
    <h1>Mentions légales</h1>

    <h3>Siège Social</h3>

    <p>
      Editions Francis Lefebvre – Tour Lefebvre Dalloz CS 80357 10, Place des Vosges
      – Courbevoie 92072 Paris La Défense Cedex -
      Tél. : 01.41.05.22.00
      <br>
      SAS au capital de 241 608 € – Siren 414 740 852 – R.C.S Nanterre –
      Siret 414 740 852 00069 – Code APE 5814Z – N° TVA intracommunautaire FR 764 147 408 52
    </p>

    <div class="spacer"></div>

    <h3>Références CNIL</h3>

    <p>
      Numéro d’enregistrement à la CNIL :
      <br>
      674727 – Nom de domaine :
      <a href="https://monassistant.efl.fr" target="_blank">https://monassistant.efl.fr</a>
    </p>

    <p>
      « Conformément à la loi du 06.01.1978, ces informations peuvent donner lieu à l’exercice
      d’un droit d’accès et de rectification auprès de notre service commercial. »
      Vous disposez d’un droit d’accès, de modification, de rectification et de suppression
      <br>
      des données qui vous concernent (art. 34 de la loi « Informatique et Libertés »).
      Pour l’exercer, adressez-vous à : Editions Francis Lefebvre –
      42, rue de Villiers – 92532 Levallois Cedex
    </p>

    <div class="spacer"></div>

    <h3>Création et Hébergement du site</h3>

    <p>
      DBM – De Bussac Multimédia
      <br>
      44 avenue des Etats-Unis
      <br>
      63000 Clermont Ferrand France
      <br>
      Tel : <a href="tel:+33473406565">+33 4 73 40 65 65</a>
      <br>
      <a href="https://www.debussac.net" target="_blank">www.debussac.net</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LegalMentions',
};
</script>
