import Vue from 'vue';
import Vuex from 'vuex';

import Config from './modules/Config';
import ProductsList from './modules/ProductsList';
import AppText from './modules/AppText';
import Product from './modules/Product';
import ProgressBar from './modules/ProgressBar';
import User from './modules/User';
import Tags from './modules/Tags';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Config,
    ProductsList,
    AppText,
    Product,
    ProgressBar,
    User,
    Tags,
  },
});
