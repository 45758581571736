var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatItem__actions"},[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"chatItem__button coral",attrs:{"href":this.url,"target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
      key: 'infos_produit1',
      label: 'Je découvre',
    })}}},[_vm._v("Je découvre")]),_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"chatItem__button",attrs:{"href":_vm.$store.state.Config.linkExternalCalendar,"target":"_blank"},on:{"click":function($event){return _vm.triggerTagClick({
      key: 'demo_clic',
      label: 'Je souhaite une démo',
    })}}},[_vm._v("Je souhaite une démo")]),_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"chatItem__button",on:{"click":function($event){$event.stopPropagation();_vm.$emit('openFormContact'),
      _vm.triggerTagClick({
        key: 'recontacter_clic',
        label: 'Vous pouvez me recontacter ?',
      })}}},[_vm._v("Vous pouvez me recontacter ?")])])}
var staticRenderFns = []

export { render, staticRenderFns }