export function tagClick(params) {
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('tagClick : %o', params);
  // }

  // expected params
  // {
  //   "tealium_event": String,
  //   "event_category": String,
  //   "event_action": String,
  //   "event_label": String,
  // }
  utag.link(params); // eslint-disable-line no-undef
}

export function tagPageView(params) {
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('tagPageView : %o', params);
  // }

  // expected params
  // {
  //   "tealium_event": String,
  //   "page_name": String,
  //   "page_url": String,
  // }
  utag.view(params); // eslint-disable-line no-undef
}
