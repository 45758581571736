<template>
  <div>
    <!-- <component v-bind:is="currentActionComponent"></component> -->
    <productCardActionsRule1
      v-if="activeComponent === 'productCardActionsRule1'"
      @openForm="$emit('openForm')"
      @openFormContact="$emit('openFormContact')"
      :url="this.url"
    />

    <productCardActionsRule2
      v-if="activeComponent === 'productCardActionsRule2'"
      @openForm="$emit('openForm')"
      @openFormContact="$emit('openFormContact')"
      :url="this.url"
    />

    <productCardActionsRule3
      v-if="activeComponent === 'productCardActionsRule3'"
      @openForm="$emit('openForm')"
      @openFormContact="$emit('openFormContact')"
      :url="this.url"
    />

    <productCardActionsRule4
      v-if="activeComponent === 'productCardActionsRule4'"
      @openForm="$emit('openForm')"
      @openFormContact="$emit('openFormContact')"
      :url="this.url"
    />
  </div>
</template>

<script>
import ProductCardActionsRule1 from './ProductCardActionsRule1.vue';
import ProductCardActionsRule2 from './ProductCardActionsRule2.vue';
import ProductCardActionsRule3 from './ProductCardActionsRule3.vue';
import ProductCardActionsRule4 from './ProductCardActionsRule4.vue';

export default {
  name: 'ProductCardActions',

  components: {
    ProductCardActionsRule1,
    ProductCardActionsRule2,
    ProductCardActionsRule3,
    ProductCardActionsRule4,
  },

  props: {
    categories: String,
    url: String,
  },

  data() {
    return {
      match1: /Navis|INNEO|Focus Expert-comptable/g,
      match2: /Mémentis/g,
      match3: /Logiciels|Services/g,
      activeComponent: '',
    };
  },

  methods: {
    updateDisplay() {
      if (this.categories) {
        if (this.categories.match(this.match1)) {
          this.activeComponent = 'productCardActionsRule1';
        } else if (this.categories.match(this.match2)) {
          this.activeComponent = 'productCardActionsRule2';
        } else if (this.categories.match(this.match3)) {
          this.activeComponent = 'productCardActionsRule3';
        } else {
          this.activeComponent = 'productCardActionsRule4';
        }
      } else {
        this.activeComponent = 'productCardActionsRule4';
      }
    },
  },

  mounted() {
    this.updateDisplay();
  },
};
</script>
