<template>
  <div class="chatItem__actions">
    <a
      :href="this.url"
      target="_blank"
      class="chatItem__button coral"
      v-ripple
      @click="triggerTagClick({
        key: 'infos_produit1',
        label: 'Je découvre',
      })"
    >Je découvre</a>

    <a
      :href="$store.state.Config.linkExternalCalendar"
      @click="triggerTagClick({
        key: 'demo_clic',
        label: 'Je souhaite une démo',
      })"
      target="_blank"
      class="chatItem__button"
      v-ripple
    >Je souhaite une démo</a>

    <a
      class="chatItem__button"
      v-ripple
      @click.stop="
        $emit('openFormContact'),
        triggerTagClick({
          key: 'recontacter_clic',
          label: 'Vous pouvez me recontacter ?',
        })
      "
    >Vous pouvez me recontacter ?</a>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProductCardActionsRule3',

  props: {
    url: String,
  },

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },
};
</script>
