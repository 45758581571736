import Vue from 'vue';

import getAppText from '../../repositories/AppText';

const AppText = {
  state: {
    text: {},
  },

  getters: {
    getText: (state) => (keys) => keys.split('.')
      .reduce((obj, key) => (obj && key ? obj[key] : null), state.text),

    getQuestion: (state) => (qId) => state.text.questions
      .filter((question) => question.id === qId),

    getAnswerLabel: (state, getters) => (qId, aId) => {
      const question = getters.getQuestion(qId);
      const answer = question[0].answers.filter((a) => a.id === aId);

      return answer.length > 0 ? answer[0].label : '';
    },

    getAnswerLabels: (state, getters) => (qId, aIds) => {
      let answer = '';

      aIds.forEach((aId) => {
        if (answer.length > 0) {
          answer += ', ';
        }
        answer += getters.getAnswerLabel(qId, aId);
      });

      return answer;
    },

    getAnswerFormattedVSelect: (state, getters) => (qId) => {
      const { answers } = getters.getQuestion(qId)[0];

      return answers.map((answer) => ({
        text: answer.label,
        value: answer.id,
      }));
    },
  },

  mutations: {
    STORE_APP_TEXT: (state, payload) => {
      Vue.set(state, 'text', payload[0]);
    },
  },

  actions: {
    fetchAppText: ({ dispatch, commit }) => getAppText.get().then((res) => {
      commit('STORE_APP_TEXT', res.data);
      dispatch('fetchSolutionOrder', res.data[0].solutionTypeOrder || undefined);
    }),
  },
};

export default AppText;
