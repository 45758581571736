import {
  configApiLead as Repository,
} from './config';

export default {
  postEmail(body, headers) {
    return Repository(headers).post(
      '/configurateur/contact',
      body,
    );
  },
};
