<template>
  <div class="chatItem__actions">
    <a
      :href="this.url"
      target="_blank"
      class="chatItem__button coral"
      v-ripple
      @click="triggerTagClick({
        key: 'infos_produit1',
        label: 'Je découvre',
      })"
    >Je découvre</a>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProductCardActionsRule4',

  props: {
    url: String,
  },

  methods: {
    ...mapActions([
      'triggerTagClick',
    ]),
  },
};
</script>
