<template>
  <div
    id="scrollHandler"
    ref="scrollContentLarge"
    class="has-smoothScroll"
  >
    <div class="chatBoxContainer"
      :class="{'has-gradient': hasGradient}"
      v-scroll:#scrollHandler="onScroll"
    >
      <chatContent
        @openForm="$emit('openForm')"
        @appStarted="$emit('appStarted')"
        @openFormContact="$emit('openFormContact', $event)"
        @mainProductSelect="$emit('mainProductSelect')"
        @contentUpdate="updateScrollPosition()"
      />
    </div>
  </div>
</template>

<script>
import ChatContent from '../chatContent/ChatContent.vue';

export default {
  name: 'ChatBox',

  components: {
    ChatContent,
  },

  props: {
    refContentSmall: Node,
  },

  data() {
    return {
      hasGradient: false,
      scrollTrigger: 60,
      scrollOptions: {
        duration: 400,
      },
    };
  },

  methods: {
    onScroll(e) {
      const offsetTop = e.target.scrollTop;
      this.hasGradient = offsetTop > this.scrollTrigger;
    },

    updateScrollPosition() {
      const windowWidth = window.innerWidth;

      window.requestAnimationFrame(() => {
        let content;
        let scrollAmount;

        if (windowWidth > 1024) {
          content = this.$refs.scrollContentLarge;
          scrollAmount = content.scrollHeight;
        } else if (typeof this.refContentSmall !== 'undefined') {
          content = window;
          scrollAmount = this.refContentSmall.scrollHeight;
        }

        if (typeof content !== 'undefined' && typeof scrollAmount !== 'undefined') {
          content.scroll(0, scrollAmount);
        }
      });
    },
  },
};
</script>

<style lang="scss">
  @import 'ChatBox.scss';
</style>
