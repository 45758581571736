<template>
  <div class="chatItem">
    <div class="chatItem__question">
      <chatItemClock v-if="!hideAvatar" />

      <avatar v-if="!hideAvatar" fileName="avatar-efl.svg"/>

      <slot name="content"></slot>
    </div>

    <transition name="fade-delay">
      <template v-if="answer">
        <div v-if="!hideAnswer && answer.length > 0" class="chatItem__answer">
          <transition name="fade">
            <a
              v-if="!readOnly"
              @click="$emit('edit')"
              class="action--edit"
              title="Modifier"
              v-ripple
            >
              <img :src="require('../../assets/images/icons/icon-pencil.svg')" alt="Edit">
            </a>
          </transition>

          <div class="chatItem__answer__content">
            <slot name="answer"></slot>
            &nbsp;
            <span v-html="answer"/>
          </div>

          <chatItemClock />
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import Avatar from '../avatar/Avatar.vue';
import ChatItemClock from '../chatItemClock/ChatItemClock.vue';

export default {
  name: 'ChatItem',

  components: {
    Avatar,
    ChatItemClock,
  },

  props: {
    answer: String,
    hideAnswer: Boolean,
    hideAvatar: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  @import 'ChatItem.scss';
</style>
